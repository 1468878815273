(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('Intervention', Intervention);

    Intervention.$inject = ['$resource', 'DateUtils'];

    function Intervention ($resource, DateUtils) {
        var resourceUrl =  'api/interventions/:id';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                url: 'api/interventions',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'addCommentary' : {
                method: 'POST',
                url:'api/interventions/:id/commentary',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                }
            },
            'update': {
                method:'PUT',
                url: 'api/interventions',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'manualClose': {
            	url:'api/interventions/:id/manualClose',
            	method: 'PUT',
            	hasBody:false
            },
            'bonusMalus': {
            	url:'api/interventions/bonusMalus',
            	method: 'PUT',
            	hasBody:false
            },
            'bonusMalusIntervention': {
            	url:'api/interventions/:id/bonusMalus',
            	method: 'PUT',
            	hasBody:false
            },
            'removeTech': {
                url:'api/interventions/:id/removeTech/:tech',
                method: 'GET'
            },
            'removeCommentary': {
                url:'api/interventions/:id/removeCommentary',
                method: 'PUT',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id"}
            },
            'addBonDeCommande': {
                url:'api/interventions/:id/addBonDeCommande',
                method: 'POST',
                hasBody: true,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id"}
            },
            'removeBonDeCommande': {
                url:'api/interventions/:id/removeBonDeCommande',
                method: 'PUT',
                hasBody: true,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id"}
            },
            'addTech': {
                url:'api/interventions/:id/addTech/:tech',
                method: 'GET'
            },
            'addActivity': {
                url:'api/interventions/createActivity',
                method: 'POST',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'removeActivity': {
                url:'api/interventions/:id/removeactivity/:code',
                method: 'GET'
            },
            'changeDate': {
                url:'api/interventions/:id/changeDate',
                method: 'PUT',
                hasBody:false,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id", date: "@date"}
            },
            'changeActivityFacture': {
                url:'api/interventions/:id/changeActivityFacture',
                method: 'PUT',
                hasBody:false,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id", activityCode: "@activityCode"}
            },
            'changeActivityCaTechnicien': {
                url:'api/interventions/:id/changeActivityCaTechnicien',
                method: 'PUT',
                hasBody:false,
                transformRequest: function (data) {
                    var copy = angular.fromJson(data);
                    delete copy.id;
                    return angular.toJson(copy);
                },
                params: {id: "@id", activityCode: "@activityCode"}
            },
            'updateTechPercentages': {
                url:'api/interventions/:id/updateTechPercentages',
                method: 'POST',
                hasBody:true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                params: {id: "@id"}
            },
        });
    }
})();
