(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$filter', 'Principal', 'LoginService', '$state', 'TechnicienHomeStat', 'ExportPraxedo', 'DateUtils'];

    function HomeController ($scope, $filter, Principal, LoginService, $state, TechnicienHomeStat, ExportPraxedo, DateUtils) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.progressBarMax = 200;
        vm.register = register;
        vm.getBackgroundColor = getBackgroundColor;
        vm.findCalendarDayInfo = findCalendarDayInfo;
        vm.viewCalendar = viewCalendar;
        vm.formatDayFr = formatDayFr;
        vm.formatMonthFr = formatMonthFr;
        vm.formatDate = formatDate;
        vm.theoricalPercentageDay = new Date().setDate(new Date().getDate() - 1);
        vm.dateStart = null;
        vm.dateEnd = null;
        vm.previousMonth = previousMonth;
        vm.actualMonth = actualMonth;
        vm.nextMonth = nextMonth;
        vm.isEquipeInWeekRange = isEquipeInWeekRange;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function getBackgroundColor(day, calendarInfos) {
            var dayInfo = vm.findCalendarDayInfo(day, calendarInfos);
            if(moment(day).isFerie()){
                return " tdGreySunday";
            }
            if (dayInfo) {
                if (dayInfo.astreinteId) {
                    return 'tdYellow';
                }
                if (dayInfo.conges && dayInfo.conges.length > 0) {
                    if (!dayInfo.conges[0].validate) {
                        return 'tdRed';
                    } else {
                        switch (dayInfo.conges[0].status) {
                            case "AT":
                                return "tdPink";
                            case "CHP":
                                return "tdGrey";
                            case "CP":
                                return "tdBlue";
                            case "JR":
                                return "tdGreen";
                            case "JD":
                                return "tdOrange";
                            case "GD":
                                return "tdPurple";
                            default:
                                return 'none';
                        }
                    }
                }
                if (dayInfo.astreintePlanifie && dayInfo.astreintePlanifie.length > 0) {
                    switch (dayInfo.astreintePlanifie[0].type) {
                        case "CUIVRE":
                            return "tdGreen";
                        case "FIBRE_CHEF_EQUIPE_BINOME":
                            return "tdYellowChef";
                        case "FIBRE_BINOME":
                            return "tdBeigeBinome";
                        case "FIBRE_CHEF_MONOME":
                            return "tdLightBrown";
                        default:
                            return 'none';
                    }
                }
            }
            return 'none';
        }

        function findCalendarDayInfo(day, calendarInfos) {
            var formattedDay = $filter('date')(day, 'yyyy-MM-dd');
            return calendarInfos.find(function(info) {
                return info.date === formattedDay;
            });
        }

        function viewCalendar(stat){
            if (stat.display == undefined || stat.display == null){
                stat.display = true;
            }else {
                stat.display = !stat.display;
            }
        }


        getAccount().then(function() {
            if(vm.isAuthenticated){
                getAllDates();
                loadStats();
                loadLastImportsDates();
            }
        })

        function loadStats() {
            var filter = {
                dateFrom: vm.dateStart.toISOString().split('T')[0],
                dateTo: vm.dateEnd.toISOString().split('T')[0]
            }

            TechnicienHomeStat.query(filter,onSuccess, onError);
        }

        function loadLastImportsDates() {
            ExportPraxedo.getLastImports({},
                function onSuccess(data){
                    vm.lastImportFiles = data;
                },
                onError)
        }

        function onSuccess(data, headers) {
            vm.technicienHomeStats = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function previousMonth() {
            vm.month = DateUtils.getFirstDayOfMonth(new Date(vm.dateEnd.getFullYear(), vm.dateEnd.getMonth() - 1, 1));
            vm.dateStart = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.dateEnd = DateUtils.getLastDayOfPeriod(vm.month);
            getWeeks();
            loadStats();
        }

        function actualMonth() {
            getAllDates();
            loadStats();
        }

        function nextMonth() {
            vm.month = DateUtils.getFirstDayOfMonth(new Date(vm.dateEnd.getFullYear(), vm.dateEnd.getMonth() + 1, 1));
            vm.dateStart = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.dateEnd = DateUtils.getLastDayOfPeriod(vm.month);
            getWeeks();
            loadStats();
        }

        function getAllDates() {
            vm.month = DateUtils.getFirstDayOfMonth(new Date());
            vm.dateStart = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.dateEnd = DateUtils.getLastDayOfPeriod(vm.month);
            getWeeks();
        }

        function getWeeks() {
            var startDate = new Date(vm.dateStart);
            var endDate = new Date(vm.dateEnd);

            // S'assurer que la date de début est un lundi
            startDate.setDate(startDate.getDate() + 1);

            var weeks = {};
            var currentWeek = [];
            var weekCounter = 1;

            while (startDate <= endDate) {
                if (startDate.getDay() !== 0) {
                    currentWeek.push(new Date(startDate));
                }

                if (startDate.getDay() === 6 || startDate.getTime() === endDate.getTime()) {
                    weeks['Semaine ' + weekCounter] = currentWeek;
                    currentWeek = [];
                    weekCounter++;
                }

                startDate.setDate(startDate.getDate() + 1);
            }

            vm.weeks = weeks;
        }

        function isEquipeInWeekRange(equipe, week) {
            var equipeStart = new Date(equipe.dateStart);

            for (var i = 0; i < week.length; i++) {
                var weekDate = new Date(week[i]);
                if (weekDate.toDateString() === equipeStart.toDateString()) {
                    return true;
                }
            }

            return false;
        }

        function formatDayFr(day) {
            var days = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];
            return days[day.getDay()];
        }

        function formatMonthFr(isoDateString) {
            var date = new Date(isoDateString);
            var options = { month: 'long' };
            return date.toLocaleString('fr-FR', options);
        }

        function formatDate(timestamp) {
            var date = new Date(timestamp);
            return date.toISOString().split('T')[0];
        }

        function getAccount() {
            return Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated();
                return account;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
